import TimerSettingItem from './TimerSettingItem';
import styles from '../HomeScreen.module.scss';

export type Props = {
    onChange?: (newValue: number) => void;
}

function DurationSettings({ onChange }: Props) {
    return (
        <div>
            <h3>Durée</h3>
            <div>120s</div>
            <div>
                <button type="button" onClick={() => onChange && onChange(120)}>120</button>
                <button type="button" onClick={() => onChange && onChange(90)}>90</button>
                <button type="button" onClick={() => onChange && onChange(60)}>60</button>
                <button type="button" onClick={() => onChange && onChange(40)}>40</button>
            </div>
        </div>
    );
}

export default DurationSettings;
