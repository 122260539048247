import React, { useEffect } from 'react';
import displayContext, { DisplayContextType } from './displayContext';
import { useTimerInfo, useTimerRequest } from '../../data/useTimer';
import { PopupBox } from '../../components/PopupBox';
import DisplayConnecting from './DisplayConnecting';

type Props = {
    children?: React.ReactNode;
};

function DisplayProvider({ children }: Props) {
    const timerInfo = useTimerInfo(1000);
    const { setupTimer } = useTimerRequest();

    useEffect(() => {
        if (timerInfo !== undefined && timerInfo.state === 'unset') {
            setupTimer({
                cycle: 'ABCD',
                round: 2,
                sound: 0,
                time: 120,
                brightness: 30
            });
        }
    }, [timerInfo, setupTimer]);

    const context = React.useMemo<DisplayContextType>(
        () => ({ info: timerInfo }),
        [timerInfo]
    );

    return (
        <displayContext.Provider value={context}>
            <PopupBox isOpen={timerInfo === undefined} variant="red">
                <DisplayConnecting />
            </PopupBox>
            {timerInfo !== undefined && children}
        </displayContext.Provider>
    );
}

export default DisplayProvider;