import classNames from 'classnames';

export type Props = {
}

function DisplayConnecting() {
    return (
        <div>
            <h3>Connecting ...</h3>
            <div>
            </div>
        </div>
    );
}

export default DisplayConnecting;
