import React from 'react';
import { HomeScreen } from './screens/home';
import { TimerScreen } from './screens/timer';
import './App.scss';
import { DisplayProvider } from './utils/display';
import DisplayRoute from './utils/display/DisplayRoute';

function App() {
    return (
        <DisplayProvider>
            <DisplayRoute state="unset,setup">
                <HomeScreen />
            </DisplayRoute>
            <DisplayRoute state="shot,end">
                <TimerScreen />
            </DisplayRoute>
        </DisplayProvider>
    );
}

export default App;
