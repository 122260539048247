import { default as appConfig } from './app-config.json';
import { default as appConfigProd } from './app-config.prod.json';

class Config {
    name: string = 'AppName';
    env: string = 'dev';
    version: string = '';
    apiUrl: string = 'http://localhost:3000';

    constructor() {
        let config = { ...appConfig };
        if (process.env.NODE_ENV === 'production') {
            config = { ...config, ...appConfigProd };
        }

        Object.assign(this, config);
    }
}

export default new Config();
