import classNames from 'classnames';
import styles from '../HomeScreen.module.scss';
import PlayIcon from '../../../components/Icons/PlayIcon';

type Props = {
    name: string;
    label: string;
    variant?: string;
    onClick?: (key: string) => void;
}

function TimerSettingItem({ name, label, variant = 'default', children, onClick }: React.PropsWithChildren<Props>) {
    return (
        <div
            role="button"
            tabIndex={0}
            className={classNames('grid-item', `grid-item-${variant}`, styles['settingItem'])}
            onClick={() => onClick && onClick(name)}
        >
            <h3 className={classNames('grid-item-title')}>
                <PlayIcon className="grid-item-icon" />
                <span>{label}</span>
            </h3>
            <div className="grid-item-content">
                <span className={styles['item-value']}>{children}</span>
            </div>
        </div>
    );
}

export default TimerSettingItem;
