import { useCallback, useState } from 'react';
import classNames from 'classnames';
import TimerSettings from './components/TimerSettings';
import Header from './components/Header';
import PlayIcon from '../../components/Icons/PlayIcon';

import styles from './HomeScreen.module.scss';
import { PopupBox } from '../../components/PopupBox';
import DurationSettings from './components/DurationSettings';
import { useDisplayInfo } from '../../utils/display';
import { useTimerRequest } from '../../data/useTimer';

function HomeScreen() {
    const displayInfo = useDisplayInfo();
    const { setupTimer, startShotSession } = useTimerRequest();
    const [showSettingsKey, setShowSettingsKey] = useState<string | null>(null);

    const handleStartTimerClick = () => {
        startShotSession();
    };

    const handleSettingsClick = useCallback((key: string) => {
        setShowSettingsKey(key);
    }, [setShowSettingsKey]);

    const onCloseSettings = useCallback(() => {
        setShowSettingsKey(null);
    }, [setShowSettingsKey]);

    const handleDurationChange = useCallback((newValue: number) => {
        setShowSettingsKey(null);
        setupTimer({
            ...displayInfo!.config,
            time: newValue
        });
    }, [setShowSettingsKey, displayInfo, setupTimer]);

    return (
        <div className="page">
            <Header />
            <div className="page-content">
                <TimerSettings
                    displayInfo={displayInfo!}
                    onClick={handleSettingsClick}
                />
                <button
                    type="button"
                    className={classNames('btn btn-primary', styles['btn-play'])}
                    onClick={handleStartTimerClick}
                >
                    <PlayIcon className="btn-icon" />
                </button>
                <PopupBox
                    isOpen={showSettingsKey === 'duration'}
                    variant="green"
                    onClose={onCloseSettings}
                >
                    <DurationSettings onChange={handleDurationChange} />
                </PopupBox>
                <PopupBox
                    isOpen={showSettingsKey === 'cycle'}
                    variant="red"
                    onClose={onCloseSettings}
                >
                    cycle
                </PopupBox>
                <PopupBox
                    isOpen={showSettingsKey === 'sound'}
                    variant="yellow"
                    onClose={onCloseSettings}
                >
                    sound
                </PopupBox>
                <PopupBox
                    isOpen={showSettingsKey === 'round'}
                    variant="blue"
                    onClose={onCloseSettings}
                >
                    round
                </PopupBox>
            </div>
        </div>
    );
}

export default HomeScreen;
