import React from 'react';
import classNames from 'classnames';

type Props = {
    className?: string;
};

function PlayIcon({ className }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 15000 15000"
            preserveAspectRatio="true"
            className={classNames('icon', className)}
        >
            <path
                fill="currentColor"
                d="M5044.05 10985.47c-102.82,0 -199.04,-19.77 -288.69,-57.99 -90.94,-39.55 -170.05,-92.27 -237.28,-158.18 -68.53,-67.23 -122.58,-146.32 -160.82,-235.97 -38.22,-90.94 -58,-187.18 -58,-290.01l0 -5483.7c0,-102.83 19.78,-199.06 58,-290.01 38.24,-89.65 90.96,-168.74 158.19,-235.96 67.23,-65.91 145,-118.64 235.95,-158.18 89.64,-38.23 185.88,-58 290.01,-58 60.64,0 122.6,7.9 184.56,23.72 61.94,15.83 119.95,38.22 173.99,69.87l4919.54 2741.85c118.63,67.23 212.22,156.87 278.13,271.56 67.23,114.68 100.18,239.91 100.18,376.99 0,138.43 -31.63,264.97 -97.54,378.34 -65.9,113.36 -159.5,203.01 -280.77,270.22l-4915.58 2741.86c-54.05,31.65 -112.04,54.05 -174,69.87 -61.96,15.83 -123.92,23.72 -185.87,23.72z"
            />
        </svg>
    );
}

export default PlayIcon;