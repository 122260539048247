export type Props = {
    strokeWidth: number,
    value?: number,
    children?: React.ReactNode
}

function CircularProgressBar({
    strokeWidth,
    value,
    children
}: Props) {
    const size = 100;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (size - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${size} ${size}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * (value ?? 0)) / 100;

    return (
        <div className="circularProgressbar">
            <svg
                viewBox={viewBox}
            >
                <defs>
                    <mask id="mask01">
                        <circle
                            id="c1"
                            cx={size / 2}
                            cy={size / 2}
                            r={radius}
                            fill="none"
                            stroke="white"
                            strokeWidth="20"
                            strokeDasharray="40 10"
                            pathLength="1000"
                        />
                    </mask>
                </defs>
                <circle
                    className="circle-background"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="transparent"
                    stroke="#dee2e6"
                    strokeWidth={`${strokeWidth}px`}
                    mask="url(#mask01)"
                />
                <circle
                    className="circle-progress"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="transparent"
                    stroke="#ff0000"
                    strokeDasharray={dashArray}
                    strokeDashoffset={dashOffset}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    mask="url(#mask01)"
                />
            </svg>
            <div className="circularProgressbar-content">
                {children}
            </div>
        </div>
    );
}

export default CircularProgressBar;
