import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { CircularProgressBar } from '../../components/CircularProgressBar';
import { useTimerRequest } from '../../data/useTimer';
import TimerSettings from './components/TimerSettings';
import PauseIcon from '../../components/Icons/PauseIcon';
import styles from './TimerScreen.module.scss';
import { useDisplayInfo } from '../../utils/display';
import { PopupBox } from '../../components/PopupBox';
import { startNextRound } from '../../data/requests/timerRequests';

function TimerScreen() {
    const navigate = useNavigate();
    const timerInfo = useDisplayInfo();
    const { resetTimer, useStartNextRound } = useTimerRequest();

    React.useEffect(() => {
        // useStartTimer();
    }, []);

    const handleBackToSetupButtonClick = () => {
        resetTimer();
    };

    const handleStopButtonClick = () => {
        // useStopTimer();
    };

    const handleNextButtonClick = () => {
        startNextRound();
    };

    const timerPercent = 100 - (timerInfo!.timer.time * 100) / (timerInfo!.config.time);

    const showNextRound = timerInfo?.timer.step === 'end';
    const isTimerEnded = timerInfo?.timer.step === 'end' && timerInfo.timer.round === timerInfo.config.round;

    return (
        <>
            <div className="page">
                <div className="page-content">
                    <div className="navBar">
                        <button type="button" onClick={handleBackToSetupButtonClick}>back</button>
                    </div>
                    <TimerSettings info={timerInfo} />
                    <div className={styles['timer']}>
                        <CircularProgressBar
                            strokeWidth={5}
                            value={timerPercent}
                        >
                            {timerInfo!.timer.time}
                        </CircularProgressBar>
                    </div>
                    <div className={styles['footer']}>
                        <button
                            className={classNames('btn btn-round', styles['btn-action'])}
                            type="button"
                            onClick={handleStopButtonClick}
                        >
                            <PauseIcon />
                        </button>
                        {showNextRound === true && (
                            <button
                                className={classNames('btn btn-round', styles['btn-action'])}
                                type="button"
                                onClick={handleNextButtonClick}
                            >
                                next
                            </button>
                        )}
                        {/* <button type="button" onClick={handleStartButtonClick}>stop</button> */}
                    </div>
                </div>
            </div>
            <PopupBox
                isOpen={isTimerEnded === true}
                variant="blue"
            >
                <button type="button" onClick={handleBackToSetupButtonClick}>Retour</button>
            </PopupBox>
        </>
    );
}

export default TimerScreen;
