import TimerSettingItem from './TimerSettingItem';
import styles from '../TimerScreen.module.scss';

export type Props = {
    info: App.Timer.TimerInfo | undefined;
}

function TimerSettings({ info }: Props) {
    return (
        <div className={styles['settings']}>
            <TimerSettingItem variant="blue" label="Round">{info?.timer.round}/{info?.config.round}</TimerSettingItem>
            <TimerSettingItem variant="red" label="Cycle">{info?.timer.shooter}</TimerSettingItem>
        </div>
    );
}

export default TimerSettings;
