import styles from '../HomeScreen.module.scss';

function Header() {
    return (
        <div className={styles['header']}>
            <h1>Chronomètre</h1>
        </div>
    );
}

export default Header;
