import React from 'react';
import classNames from 'classnames';

type Props = {
    className?: string;
};

function PauseIcon({ className }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 125"
            preserveAspectRatio="true"
            className={classNames('icon', className)}
        >
            <path
                fill="currentColor"
                d="M29.719,5c-5.777,0-10.457,4.683-10.457,10.46v69.08c0,5.777,4.68,10.46,10.457,10.46s10.463-4.683,10.463-10.46V15.46    C40.182,9.683,35.495,5,29.719,5z"
            />
            <path
                fill="currentColor"
                d="M70.276,5c-5.777,0-10.457,4.683-10.457,10.46v69.08c0,5.777,4.68,10.46,10.457,10.46c5.777,0,10.463-4.683,10.463-10.46    V15.46C80.739,9.683,76.053,5,70.276,5z"
            />
        </svg>
    );
}

export default PauseIcon;