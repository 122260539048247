import { useEffect, useState } from 'react';
import { timerRequests } from './requests';

export const useTimerInfo = (refreshInterval?: number): App.Timer.TimerInfo | undefined => {
    const [info, setInfo] = useState<App.Timer.TimerInfo | undefined>(undefined);

    useEffect(() => {
        if (!Number.isNaN(refreshInterval)) {
            const interval = setInterval(async () => {
                try {
                    const timerInfo = await timerRequests.getTimerInfo();
                    if (timerInfo != null) {
                        setInfo({
                            state: timerInfo.state,
                            config: {
                                round: timerInfo.config.round,
                                sound: timerInfo.config.sound,
                                time: timerInfo.config.time,
                                cycle: timerInfo.config.cycle,
                                brightness: timerInfo.config.brightness
                            },
                            timer: {
                                round: timerInfo.timer.round,
                                time: timerInfo.timer.time,
                                shooter: timerInfo.timer.shooter,
                                step: timerInfo.timer.step
                            }
                        });
                    }
                    else {
                        setInfo(undefined);
                    }
                }
                catch (ex) {
                    console.warn(ex);
                    setInfo(undefined);
                }
            }, refreshInterval);

            return () => {
                clearInterval(interval);
            };
        }
        return () => { };
    }, [refreshInterval]);

    return info;
};

export const useTimerRequest = (): any => {
    return {
        setupTimer: async (timerConfig: App.Timer.TimerConfig): Promise<any> => await timerRequests.setupTimer(timerConfig),
        startShotSession: async (): Promise<any> => await timerRequests.startShotSession(),
        startNextRound: async (): Promise<any> => await timerRequests.startNextRound(),
        resetTimer: async (): Promise<any> => await timerRequests.reset(),
    };
};

// export const useSetupTimer = async (timerConfig: App.Timer.TimerConfig): Promise<any> => {
//     return {
//         setupTimer: () => await timerRequests.setupTimer(timerConfig);
//     };
// };

// export const useStartShotSession = async (): Promise<any> => {
//     await timerRequests.startShotSession();
// };

// export const useStartNextRound = async (): Promise<any> => {
//     await timerRequests.startNextRound();
// };

// export const useResetTimer = async (): Promise<any> => {
//     await timerRequests.reset();
// };