import TimerSettingItem from './TimerSettingItem';
import styles from '../HomeScreen.module.scss';

export type Props = {
    displayInfo: App.Timer.TimerInfo;
    onClick?: (item: string) => void;
}

function TimerSettings({ displayInfo, onClick }: Props) {
    return (
        <div className={styles['settings']}>
            <h2 className="section-title">Paramêtres du minuteur</h2>
            <div className={styles['settings-items']}>
                <TimerSettingItem name="duration" variant="green" label="Durée" onClick={onClick}>{displayInfo.config.time}s</TimerSettingItem>
                <TimerSettingItem name="cycle" variant="red" label="Cycle" onClick={onClick}>{displayInfo.config.cycle}</TimerSettingItem>
                <TimerSettingItem name="round" variant="blue" label="Round" onClick={onClick}>{displayInfo.config.round}x</TimerSettingItem>
                <TimerSettingItem name="sound" variant="yellow" label="Son" onClick={onClick}>{displayInfo.config.sound}</TimerSettingItem>
            </div>
        </div>
    );
}

export default TimerSettings;
