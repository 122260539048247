import { config } from '../../config';

export const getTimerInfo = async (): Promise<App.Timer.TimerInfo> => {
    const url = new URL(`${config.apiUrl}/api/timer`);

    const requestOptions = {
        method: 'GET',
        signal: AbortSignal.timeout(1000)
    };

    try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data as App.Timer.TimerInfo;
    }
    catch (err: any) {
        const errMessage = err?.message ?? 'no further detail';
        throw new Error(`an error occurred while calling crew API (${errMessage}})`);
    }
};

export const setupTimer = async (timerConfig: App.Timer.TimerConfig): Promise<any> => {
    const url = new URL(`${config.apiUrl}/api/timer`);
    url.searchParams.append('sound', timerConfig.sound.toString());
    url.searchParams.append('round', timerConfig.round.toString());
    url.searchParams.append('time', timerConfig.time.toString());
    url.searchParams.append('cycle', timerConfig.cycle.toString());
    url.searchParams.append('brightness', '50');

    const requestOptions = { method: 'POST' };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
};

export const startShotSession = async (): Promise<any> => {
    const url = new URL(`${config.apiUrl}/api/timer/shot`);
    const requestOptions = { method: 'POST' };

    const response = await fetch(url, requestOptions);
};

export const startNextRound = async (): Promise<any> => {
    const url = new URL(`${config.apiUrl}/api/timer/nextround`);
    const requestOptions = { method: 'POST' };

    const response = await fetch(url, requestOptions);
};

export const reset = async (): Promise<any> => {
    const url = new URL(`${config.apiUrl}/api/timer/reset`);
    const requestOptions = { method: 'POST' };

    const response = await fetch(url, requestOptions);
};

// export const startTimer = async (): Promise<any> => {
//     const url = new URL(`${config.apiUrl}/api/timer/start`);
//     const requestOptions = { method: 'POST' };

//     const response = await fetch(url, requestOptions);
// };

// export const stopTimer = async (): Promise<any> => {
//     const url = new URL(`${config.apiUrl}/api/timer/stop`);
//     const requestOptions = { method: 'POST' };

//     const response = await fetch(url, requestOptions);
// };