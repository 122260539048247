import React from 'react';
import { useDisplayInfo } from './useDisplayInfo';

type Props = {
    state: string;
    children?: React.ReactNode;
};

function DisplayRoute({ state, children }: Props) {
    const displayInfo = useDisplayInfo();

    const stateParts = React.useMemo(() => state.split(','), [state]);
    if (displayInfo !== undefined && stateParts.indexOf(displayInfo.state.trim()) === -1) return null;

    return (
        <div>
            {children}
        </div>
    );
}

export default DisplayRoute;