import classNames from 'classnames';

export type Props = {
    variant?: string;
    isOpen?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}

function PopupBox({ isOpen, variant, onClose, children }: Props) {
    return (
        <div
            className={classNames(
                'popup-wrap',
                isOpen ? 'popup-wrap__open' : null
            )}
        >
            <div
                role="button"
                tabIndex={0}
                className={classNames('popup-box', variant ? `popup-box__${variant}` : null)}
                onClick={onClose}
            >
                {children}
            </div>
        </div>
    );
}

export default PopupBox;
